import Logo from "../../assets/logo-light.png"
import { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import MobileMenuIcon from "../../assets/menu.svg"
import CloseMenuIcon from "../../assets/close-icon.svg"
import "./Navbar.css"
import { NavbarProps } from "./Navbar.types"

const Navbar = (props: NavbarProps) => {

  const { currentPage } = props

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const pageNames = ['Home', 'About', 'Contact', "Donate"]
  const pageLinks = ['/', '/about', '/contact', "/donate"]
  const navbarRef = useRef<HTMLDivElement>(null)

  /**
    * Flips the show mobile menu variable.
  */
  const didSelectMobileMenu = () => {    
    setShowMobileMenu(!showMobileMenu)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", didClickOutsideNav);
      return () => {
        document.removeEventListener("mousedown", didClickOutsideNav);
    }
  }, [navbarRef])

  /**
    * Hides the mobile nav.
  */
  const didClickOutsideNav = (event: MouseEvent) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
      setShowMobileMenu(false)
    }
  }

  return (
    <div className='navbar-container' data-testid='nav-bar' ref={navbarRef}>
        <Link to='/'>
          <img className='logo' data-testid='logo' src={Logo} alt='act logo' style={{marginBottom: '-6px'}}/>
        </Link>
        <img className='nav-burger-menu' src={MobileMenuIcon} onClick={didSelectMobileMenu} alt='menu'/>

        <div className={showMobileMenu ? 'nav-items' : 'nav-items nav-items-hidden'}>
          <img className='close-menu-button' src={CloseMenuIcon} onClick={didSelectMobileMenu} alt='close menu'/>

          {pageNames.map((name, key) => (
              <div key={key} data-testid={`element-${key}`}>
                  <Link className='nav-item' to={pageLinks[key]}><li>{name}</li></Link>

                  { key === currentPage && (
                      <div className='underline' />
                  )}
              </div>
          ))}

          {/* <a
            className='nav-donate-button temp-donate-button'
            href="https://www.stewardship.org.uk/partners/20406643"
            target="_blank"
            rel="noreferrer"
            data-testid='donate-button'
          >
          Donate
          </a> */}
        </div>
    </div>
  )
}

Navbar.defaultProps = {
  currentPage: 0
}

export default Navbar