import Navbar from "../../components/Navbar/Navbar"
import { useEffect, useState } from "react"
import { AboutContent, HomeContent, TeamMemberType, defaultProjects } from "../../types/types"
import "./AboutPage.css"
import Footer from "../../components/Footer/Footer"
import TeamMember from "./components/TeamMember"

const AboutPage = () => {

  const [content, setContent] = useState<AboutContent>()

  useEffect(() => {
    getPageContent("AboutPage").then(r => setContent(r))
  }, [])

  const OPTIONS = {
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
  }

  const getPageContent = async (page: string) => {
    const path = './content/' + page + '/content.json';
    const data = await fetch(path, OPTIONS)
    return data.json()
  }

  return (
    <>
      <Navbar />
      <img className="hero-image" src={content?.heroImage ?? ""} alt="Hero" />
      <div className="home-page-container">
        <div className="about-soma-salama-section">
          <h1>{content?.title}</h1>
          <p>{content?.aboutParagraphOne}</p>
          <br />
          <p>{content?.aboutParagraphTwo}</p>
        </div>
      </div>

      <img className="hero-image section-image" src={content?.sectionDividerImage} alt="Children sitting" />

      <div className="about-naumbu-section">
        <h2>{content?.heading1}</h2>
        <p>{content?.aboutNaumbu}</p>
      </div>

      <div className="team-section">
        <div>
          <h2>{content?.heading2}</h2>
          <p>{content?.teamText}</p>
        </div>

        <div className="team-members-container">
          {content?.teamMembers.map((teamMember: TeamMemberType, key) => (
            <TeamMember
              key={key}
              teamMember={teamMember}
            />
          ))}
        </div>

        
      </div>
      <Footer />
    </>
  )
}

export default AboutPage
