import EmailIcon from "../../assets/email-icon.svg"
import "./Footer.css"

const Footer = () => {
  return (
    <div className="footer-container">
      <div>
        <h3>Contact</h3>
        <div className="contact">
          <img className="footer-icon" src={EmailIcon} alt="Email" />
          <p>joan.meddy@gmail.com</p>
        </div>
      </div>

      <div>
        <h3>Partners</h3>
        <a className="act-link" href="https://act-tanzania.org/">Action and Compassion for Tanzania</a>
      </div>
    </div>
  )
}

export default Footer