import { useEffect, useState } from "react"
import { ProjectSection } from "../../types/types"
import Project from "../../components/Project/Project"


const MensutralHealthPage = () => {
  const [content, setContent] = useState<ProjectSection>()

  useEffect(() => {
    getPageContent("MensturalHealthPage").then(r => {
      setContent(r)
      console.log(r);
      
    })
  }, [])

  /**
   * Fetch options
   */
  const OPTIONS = {
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
  }

  const getPageContent = async (page: string) => {
    const path = './content/' + page + '/content.json';
    const data = await fetch(path, OPTIONS)
    return data.json()
  }
  
  return (
    <Project projectSection={content} />
  )
}

export default MensutralHealthPage