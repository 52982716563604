import "./TeamMember.css"
import { TeamMemberType } from "../../../types/types"
import { TeamMemberProps } from "./TeamMember.types"

const TeamMember = (props: TeamMemberProps) => {

  const { teamMember } = props

  return (
    <div className="team-member-container">
      <img className="team-member-image" src={teamMember.image} alt={`${teamMember.name}`} />
      <p className="team-member-name">{teamMember.name}</p>
      <p className="team-member-position">{teamMember.position}</p>
    </div>
  )
}

export default TeamMember