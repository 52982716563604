import { ProjectCardProps } from "./ProjectCard.types"
import "./ProjectCard.css"
import { Link } from "react-router-dom"

const ProjectCard = (props: ProjectCardProps) => {

  const { project } = props

  return (
    <Link className="project-card-container" to={project.link}>
      <img className="project-card-image" src={project.url} alt={project.title} />
      <div className="project-card-content">
        <h3>{project.title}</h3>
        <p>{project.text}</p>
      </div>
    </Link>
  )
}

export default ProjectCard