import ProjectCard from "../../../components/ProjectCard/ProjectCard"
import { ProjectSelectorPropes } from "./ProjectSelector.types"
import NextIcon from "../../../assets/next-circle-icon.svg"
import BackIcon from "../../../assets/back-circle-icon.svg"
import { useState } from "react"
import "./ProjectSelector.css"

const ProjectSelector = (props: ProjectSelectorPropes) => {

  const { projects } = props
  
  const [selectedProject, setSelectedProject] = useState<number>(0)

  const onNextProject = () => {
    if (selectedProject < projects.length-1) {
      setSelectedProject(selectedProject+1)
    }
  }

  const onPreviousProject = () => {
    if (selectedProject > 0) {
      setSelectedProject(selectedProject-1)
    }
  }

  return (
    <div className="project-selector-container">
      <img className={selectedProject > 0 ? "arrow" : "arrow arrow-disabled"} src={BackIcon} onClick={onPreviousProject} alt="Back" />
      <ProjectCard project={projects[selectedProject]} />
      <img className={selectedProject < projects.length-1 ? "arrow" : "arrow arrow-disabled"} src={NextIcon} onClick={onNextProject} alt="Next" />      
    </div>
  )
}

export default ProjectSelector