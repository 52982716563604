import { BrowserRouter, Routes, Route } from "react-router-dom"
import HomePage from "./pages/HomePage/HomePage"
import AboutPage from "./pages/AboutPage/AboutPage"
import MensutralHealthPage from "./pages/MensturalHealthPage/MensturalHealthPage"
import MaternityPage from "./pages/MaternityPage/MaternityPage"
import BoreholePage from "./pages/BoreholePage/BoreholePage"
import DonatePage from "./pages/DonatePage/DonatePage"
import ContactPage from "./pages/ContactPage/ContactPage"

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/menstrual-health" element={<MensutralHealthPage />} />
      <Route path="/maternity-clinic" element={<MaternityPage />} />
      <Route path="/boreholes" element={<BoreholePage />} />
      <Route path="/donate" element={<DonatePage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
