import { useEffect, useState } from "react"
import Navbar from "../../components/Navbar/Navbar"
import { DonatePageContent } from "../../types/types"
import Footer from "../../components/Footer/Footer"
import "./DonatePage.css"

const DonatePage = () => {

  const [content, setContent] = useState<DonatePageContent>()

  useEffect(() => {
    getPageContent("DonatePage").then(r => setContent(r))
  }, [])

  /**
   * Fetch options
   */
  const OPTIONS = {
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
  }

  const getPageContent = async (page: string) => {
    const path = './content/' + page + '/content.json';
    const data = await fetch(path, OPTIONS)
    return data.json()
  }
  
  return (
    <div className="contact-page-container">
      <div>
        <Navbar />
        <img className="hero-image" src={content?.heroImage ?? ""} alt="Hero" />
        <div className="home-page-container">
          <div className="donate-section">
            <h1>{content?.title ?? ""}</h1>
            <p className="mission-text">{content?.columnOne ?? ""}</p>
            <p className="mission-text">{content?.columnTwo?? ""}</p>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DonatePage