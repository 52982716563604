import Navbar from "../../components/Navbar/Navbar"
import Footer from "../../components/Footer/Footer"
import { useEffect, useState } from "react"
import { HomeContent, defaultProjects } from "../../types/types"
import "./HomePage.css"
import ProjectSelector from "./components/ProjectSelector"
import { Link } from "react-router-dom"

const HomePage = () => {

  const [content, setContent] = useState<HomeContent>()

  useEffect(() => {
    getPageContent("HomePage").then(r => setContent(r))
  }, [])

  /**
   * Fetch options
   */
  const OPTIONS = {
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
  }

  const getPageContent = async (page: string) => {
    const path = './content/' + page + '/content.json';
    const data = await fetch(path, OPTIONS)
    return data.json()
  }

  return (
    <>
      <Navbar />
      <img className="hero-image" src={content?.heroImage ?? ""} alt="Hero" />
      <div className="home-page-container">
        <div className="donate-section">
          <h1>{content?.title ?? ""}</h1>
          <p className="mission-text">{content?.mission ?? ""}</p>
          <Link className="button" to="/donate">{content?.donateButton ?? ""}</Link>
        </div>
        
        <img className="section-image" src={content?.sectionDividerImage} alt="Children sitting" />

        <div className="project-section">
          <div>
            <h2>{content?.projectSectionTitle}</h2>
            <p>{content?.projectSectionSubtitle}</p>
          </div>

          <ProjectSelector projects={content?.projects ?? defaultProjects} />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default HomePage