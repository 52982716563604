export type HomeContent = {
  title: string;
  mission: string;
  heroImage: string;
  donateButton: string;
  sectionDividerImage: string;

  projectSectionTitle: string;
  projectSectionSubtitle: string;

  projects: Project[]
}

export type Project = {
  url: string;
  title: string;
  text: string;
  link: string;
}

export type AboutContent = {
  title: string;
  aboutParagraphOne: string;
  aboutParagraphTwo: string;
  heroImage: string;
  sectionDividerImage: string;

  heading1: string;
  aboutNaumbu: string;

  heading2: string;
  teamText: string;
  teamMembers: TeamMemberType[];
}

export type TeamMemberType = {
  image: string;
  name: string;
  position: string;
}

export type ProjectSection = {
  title: string;
  heroImage: string;
  overview: string;
  sectionImage: string;
  columnOne: string;
  columnTwo: string;
  imageGrid: string[];
  latestNews?: ProjectNews;
  hisotry: ProjectNews[];
}

export type ProjectNews = {
  title: string;
  columnOne: string;
  columnTwo: string;
  imageGrid: string[];
}

export type DonatePageContent = {
  title: string;
  columnOne: string;
  columnTwo: string;
  heroImage: string;
}

export type ContactPageContent = {
  title: string;
  heroImage: string;
  text: string;
}



export const defaultProjects: Project[] = [{
  url: "Image",
  title: "Title",
  text: "Text",
  link: "/"
}]