import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { ProjectProps } from "./Project.types";
import "./Project.css"
import { Link } from "react-router-dom";

const Project = (props: ProjectProps) => {
  const { projectSection } = props

  return (
    <>
      <Navbar />
      <img className="hero-image" src={projectSection?.heroImage} alt="Hero" />
      <div className="home-page-container">
        <div className="donate-section">
          <h1>{projectSection?.title}</h1>
          <p className="mission-text">{projectSection?.overview}</p>
          <Link className="button" to="/donate">Donate</Link>
        </div>

        <img className="section-image" src={projectSection?.sectionImage} alt="Hero" />

        <div className="project-descriptions">
          <p>{projectSection?.columnOne}</p>
          <p>{projectSection?.columnTwo}</p>

          <div className="image-grid first-grid">
            <img className="project-grid-image" src={projectSection?.imageGrid[0]} alt="Menstrual health" />
            <img className="project-grid-image span-image" src={projectSection?.imageGrid[1]} alt="Menstrual health" />
            <img className="project-grid-image span-image" src={projectSection?.imageGrid[2]} alt="Menstrual health" />
            <img className="project-grid-image" src={projectSection?.imageGrid[3]} alt="Menstrual health" />
          </div>
        </div>

        {projectSection?.latestNews !== undefined && (
          <div className="latest-news-section">
            <h1>{projectSection?.latestNews.title}</h1>
            <p>{projectSection?.latestNews.columnOne}</p>
            <p>{projectSection?.latestNews.columnTwo}</p>

            <div className="trip-images-grid">
              {projectSection?.latestNews.imageGrid.map((image, key) => (
                <img key={key} className="trip-image" src={image} alt="Latest trip" />
              ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}

export default Project